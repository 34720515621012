import React, { useEffect, useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import chevyImage from '../assets/img/chevy.png';

import './Landing.css';

import BackgroundDesign from './BackgroundDesign';
import Facebook from '../assets/icon/facebook.svg';
import Twitter from '../assets/icon/twitter.svg';

const Landing = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [pageUrl, setPageUrl] = useState(null);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let url = params.get('img');
    if (url) {
      setImageUrl(url);
      setPageUrl(window?.location.href);
    }
  }, []);

  return (
    <div className="main-wrapper">
      <div className="bg-pink">
        <BackgroundDesign classes="design-1 " />
        <BackgroundDesign classes="design-2 " />
        <BackgroundDesign classes="design-3 " />
        <BackgroundDesign classes="design-4 " />
        <div className="contain-wrapper">
          <div>
            <h1>
              Your #WeDriveFor{' '}
              <span>
                <img src={chevyImage} className="chevy-logo" />
              </span>{' '}
              Photo
            </h1>
            {imageUrl ? (
              <>
                <img className="user-image" src={imageUrl} alt="User Image" />
                <div className="social-icons">
                  <FacebookShareButton url={pageUrl}>
                    <img
                      className="social-icon"
                      src={Facebook}
                      alt="Facebook Icon"
                    />
                  </FacebookShareButton>
                  <TwitterShareButton url={pageUrl}>
                    <img
                      className="social-icon"
                      src={Twitter}
                      alt="Twitter Icon"
                    />
                  </TwitterShareButton>
                </div>
              </>
            ) : (
              <div>No image available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
